.ant-select-focused .ant-select-selection,
.ant-select-selection:focus,
.ant-select-selection:active {
  //border-color: #55b2ff;
  border-color: #3248b2;
  border-right-width: 1px !important;
  outline: 0;
  box-shadow: 0 0 0 2px rgba(0, 97, 152, 0.2);
}
.ant-select-selection:hover {
  // border-color: #55b2ff;
  border-color: #3248b2;
  border-right-width: 1px !important;
}
.ant-select-dropdown.ant-select-dropdown--multiple
  .ant-select-dropdown-menu-item-selected
  .ant-select-selected-icon {
  display: inline-block;
  color: #55b2ff;
}
.ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
  //background-color: #e6f7ff;
  background-color: #c4cdfb;
}

.ant-select {
  color: #000;
}

.ant-select-dropdown-menu-item {
  color: #000 !important;
}
