@import "../../assets/scss/core";

// Fix for Nav Menu
#app-base-layout .baseSider {
  margin-top: 48px;
}

#appstore\$Menu.ant-menu-vertical {
  display: none;
}

.ant-menu-inline-collapsed .ant-menu-submenu-title .anticon + span {
  display: inline-block;
  max-width: 0;
  opacity: 0;
}

#app-base-layout {
  height: 100%;
  font-size: 12px !important;

  .profile-wrapper {
    text-align: center;
  }

  .trigger {
    border: 0;
    font-size: 14px;
    left: 150px;

    i {
      vertical-align: middle;
    }
  }

  .collapse-trigger {
    border: 0;
    font-size: 14px;

    i {
      vertical-align: middle;
    }
  }

  // aside.ant-layout-sider.ant-layout-sider-dark {
  //   box-shadow: rgb(183, 183, 183) 0px 0px 4px 1px;
  // }
  .ant-layout-sider {
    transition: all 0.3s;
  }

  .baseSider {
    overflow: auto;
    height: 100vh;
    position: fixed;
    left: 0px;
    flex: 0 0 200px;
    max-width: 200px;
    min-width: 200px;
    width: 200px;

    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
      overflow: auto;
      overflow-x: hidden;
      // background: url(../Dashboard/images/sideNav.jpg);
      background-repeat: no-repeat;
      background-size: cover;
      background-color: #0a1b68 !important;

      .profile-wrapper {
        flex: 0.4;

        .ant-btn {
          background: #e8e8e8;
          color: #6c757d;
          font-size: 0.832rem;
          border: 2px solid #cccccc;
        }

        .ant-btn:hover {
          color: rgb(0, 0, 0);
        }

        i.anticon.anticon-logout {
          padding-bottom: 3px;
          font-size: 1rem;
          font-weight: bold;
        }

        h6 {
          color: #fff;
        }

        p {
          color: #fff;
        }
      }
    }

    .ant-layout-sider-trigger {
      // background: transparent;
      text-align: left !important;
      padding-left: 14px !important;
      width: 199px;
      top: 54px !important;
      padding-top: 3px !important;
      left: 0px !important;
      background: linear-gradient(
        18deg,
        rgb(23, 41, 115) 0%,
        rgb(16, 34, 110) 100%
      );
      // box-shadow: 0px -1px 3px #ccc !important;
      // border-bottom: solid 1px #030c35 !important;
    }

    .sidebar-overlay-dark .ant-menu.ant-menu-sub.ant-menu-inline {
      margin-left: 0 !important;
    }

    .ant-menu-sub.ant-menu-inline {
      color: #fff;
      background: transparent;
      box-shadow: inset 0 -1px 1px 0 #0a1b68, inset 0 2px 3px 0 #0a1b68;
    }

    .ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
      left: 0;
      padding: 0 22px !important;
      text-overflow: clip;
    }
  }
}

.action-landing {
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;

  @include font-source-ui(false, false, false, false, "Inter UI");

  .ant-card-body {
    padding: 24px;
  }
}

.hello-card {
  background-color: rgb(10, 75, 178);
  border: solid 3px #074794;
  border-radius: 10px;
  box-shadow: 5px 5px 1px 0px #d3e1f7;
  float: right;
  height: 300px;
  margin: 10% 5% 0% 2%;
  width: 352px;
}

.hello-card h5 {
  color: #fff;
  font-size: 1.025rem;
  margin-top: 2rem;
}

.hello-card h4 {
  color: #fff;
}

.header-row {
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 5px -2px 2px 3px #ccc;
}

.fixed-footer {
  border-top: solid 0.05em;
  bottom: 15px;
  padding-top: 10px;
  position: absolute;
  width: 90%;
  z-index: 999;
}

.action-footer {
  margin-top: 3rem;

  .ant-btn {
    background: rgb(10, 75, 178);
    margin: 0px;
    align-items: center;
    border: solid 2px #fff;
    color: #fff;
    font-size: 0.832rem;
  }
}

.landing-footer {
  background-color: rgba(33, 36, 41, 0.8);
  bottom: 0px;
  color: #fff;
  font-size: 12px;
  height: 30px;
  line-height: 35px;
  padding-left: 10px;
  position: fixed;
  width: 100%;
}

.sidebar-overlay-dark {
  background: rgba(235, 235, 240, 0.89);
  height: 100%;

  ul {
    color: rgb(22, 14, 14);

    .ant-menu-submenu-title {
      padding-left: 24px;
      color: white;

      /* background-color: blue; */
      // margin-left: -25px;
      // font-size: 12px;
      i.ant-menu-submenu-arrow,
      i.ant-menu-submenu-arrow:hover {
        color: rgb(8, 4, 4);
      }

      i.ant-menu-submenu-arrow:before,
      i.ant-menu-submenu-arrow:after {
        // background: linear-gradient(to right, #ffffff, #ffffff);
        background-image: none;
      }

      &:hover {
        background: rgb(1, 10, 60);
        color: #fff;
        background: #99a6d3;

        i.ant-menu-submenu-arrow,
        i.ant-menu-submenu-arrow:before,
        i.ant-menu-submenu-arrow:after {
          background: linear-gradient(to right, #ffffff, #ffffff);
          background-image: none;
          color: #fff;
        }
      }
    }

    li.ant-menu-item.ant-menu-item-selected {
      background-color: #99a6d3;
      margin-bottom: -2px;
    }

    //   .ant-menu-vertical .ant-menu-item:not(:last-child),
    //    .ant-menu-vertical-left .ant-menu-item:not(:last-child),
    //     .ant-menu-vertical-right .ant-menu-item:not(:last-child),
    //     .ant-menu-inline .ant-menu-item:not(:last-child) {
    //       margin-bottom: -11px;
    //       // margin-top: 19px;
    // }

    .ant-menu-vertical .ant-menu-item:not(:last-child),
    .ant-menu-vertical-left .ant-menu-item:not(:last-child),
    .ant-menu-vertical-right .ant-menu-item:not(:last-child),
    .ant-menu-inline .ant-menu-item:not(:last-child) {
      margin-bottom: -10px;
    }
  }

  li.ant-menu-item {
    a {
      color: rgb(10, 5, 5);
    }

    &:active {
      background: #000;
    }

    &:hover {
      background: #99a6d3;
    }
  }

  .ant-menu-submenu > .ant-menu {
    background: #3248b2;
    // margin-bottom: -118px;
  }

  .ant-menu.ant-menu-sub.ant-menu-inline {
    margin-left: -25px;
  }

  & > ul {
    flex: 1;
    position: relative;

    li#appstore {
      & > ul {
        height: 235px;
        overflow-x: hidden;
        overflow-y: auto;

        /* Let's get this party started */
        &::-webkit-scrollbar {
          width: 8px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
          /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3);  */
          /* -webkit-border-radius: 10px; */
          /* border-radius: 10px; */
          background: transparent;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
          /* -webkit-border-radius: 10px; */
          /* border-radius: 10px; */
          -webkit-border-radius: 4px;
          background: grey;
          /* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);  */
        }

        &::-webkit-scrollbar-thumb:window-inactive {
          -webkit-border-radius: 4px;
          background: grey;
        }
      }
    }
  }
}

.sidebar-overlay-dark {
  background: #5a658b;
}

.sidebar-overlay-dark li.ant-menu-item a {
  color: #fff;
}

#app-base-layout .baseSider .ant-layout-sider-trigger {
  text-align: left;
  padding-left: 24px;
}

.position-fixed {
  position: fixed;
}

.sidebar-overlay-dark {
  > .ant-menu-root {
    height: calc(100% - 200px);

    // > .ant-menu-item {
    //   &:nth-last-child(2) {
    //     position: absolute;
    //     bottom: 20px;
    //   }
    //   &:nth-last-child(1) {
    //     position: absolute;
    //     bottom: -11px;
    //   }
    // }
    #appstore {
      margin-top: 10px;
      border-top: 1px solid #5a658b;
    }
  }
}

aside.ant-layout-sider > .ant-layout-sider-children {
  height: 94% !important;
}

.ant-menu-inline-collapsed {
  width: 60px;
}

.ant-menu-inline-collapsed > .ant-menu-item,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  left: 0;
  padding: 0 22px !important;
  text-overflow: clip;
}

.ant-menu-inline-collapsed > .ant-menu-item .anticon,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item
  .anticon,
.ant-menu-inline-collapsed
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .anticon,
.ant-menu-inline-collapsed
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .anticon {
  margin: 0px;
  font-size: 14px;
  line-height: 38px;
}

//Align icon with collapsed sidebar
.app-base-header {
  margin-left: -8px !important;
}

.ant-menu-vertical
  > .ant-menu-submenu-vertical
  > .ant-menu-submenu-title
  .anticon {
  margin-top: 0px !important;
  margin-left: 0px !important;
  font-size: 14px;
  line-height: 38px;
}

.ant-menu-submenu-placement-rightTop.ant-menu-submenu-popup,
.ant-menu-submenu.ant-menu-submenu-popup.ant-menu-light {
  background: linear-gradient(18deg, #6271b4 0%, #0e206c 100%) !important;

  ul {
    .ant-menu-submenu-title {
      color: #fff !important;
      margin-left: -5px !important;

      i.ant-menu-submenu-arrow:before,
      i.ant-menu-submenu-arrow:after {
        background-image: none;
      }

      &:hover {
        background: #99a6d3;
      }
    }

    li.ant-menu-item {
      a {
        margin-left: -30px !important;
        color: #fff !important;
      }

      color: #fff !important;
    }
  }

  .ant-menu.ant-menu-sub.ant-menu-vertical {
    background: transparent !important;
  }
}

li.ant-menu-submenu > .ant-menu-submenu-title > a {
  color: #fff !important;
}

.ant-menu-inline > .ant-menu-submenu-inline > .ant-menu-submenu-title .anticon {
  margin-top: 0px !important;
  margin-left: -2px !important;
  font-size: 14px;
  line-height: 38px;
}

.ant-menu-inline > .ant-menu-item .anticon,
.ant-menu-inline
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-item
  .anticon,
.ant-menu-inline
  > .ant-menu-item-group
  > .ant-menu-item-group-list
  > .ant-menu-submenu
  > .ant-menu-submenu-title
  .anticon,
.ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title .anticon {
  margin: 0px;
  font-size: 14px;
  line-height: 38px;
}

.ant-menu-item .anticon + span,
.ant-menu-submenu-title .anticon + span {
  padding-left: 10px !important;
}
