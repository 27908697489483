.ant-card-extra a {
  color: #006197;
}
.ant-card-bordered {
  border: 1px solid #7b7b7b;
  box-shadow: 0 4px 8px -2px rgba(9, 30, 66, 0.25),
    0 0 1px 0 rgba(9, 30, 66, 0.31);
  border-radius: 5px;
}
.ant-card-head {
  border-bottom: 1px solid #7b7b7b;
  font-weight: bold;
}
