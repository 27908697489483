$estee-base-font-family: "Inter UI";
$estee-body-copy: 12px;
$estee-body-active-header: 12px;
$estee-body-inactive-header: 16px;
$estee-body-smaller-header: 16px;
$estee-body-main-header: 24px;
$estee-body-featured-headline: 36px;
$estee-icon-font-size: 20px;
$estee-message-icon-font-size: 16px;
$estee-dropdown-font-size: 14;
$base-font-face: "Inter UI";

/* Adding Noto Sans */
$estee-noto-font-family: "Noto Sans", sans-serif;

.Form-Field-Title {
  font-family: "Noto Sans", sans-serif;
  font-size: 13px;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
}
