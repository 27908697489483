.cornerstone-dropdown-table {
  .table {
    display: table;
  }
  .tr {
    display: table-row;
  }
  .thead {
    display: table-header-group;
  }
  .tbody {
    display: table-row-group;
  }
  .tfoot {
    display: table-footer-group;
  }
  .col {
    display: table-column;
  }
  .colgroup {
    display: table-column-group;
  }
  .td,
  .th {
    display: table-cell;
  }
  .caption {
    display: table-caption;
  }
}
.cornerstone-dropdown-table {
  ul {
    display: table-row-group;
    li {
      display: table-row;
    }
  }
}

.cornerstone-dropdown-table {
  .table {
    .thead {
      font-weight: bolder;
      .th {
        text-transform: uppercase;
      }
    }
    .th,
    .td {
      padding: 5px 8px;
      border: solid 1px #e1e1ea;
    }
    li:after,
    li:before {
      content: none;
    }
    .ant-select-dropdown-menu-item-selected {
      background-color: aliceblue;
    }
    ul > li > .ant-empty {
      margin: auto;
    }
    .ant-select-dropdown-menu-item {
      padding: 0;
    }
  }
}

.cornerstone-dropdown-table.unset-min-width {
  width: auto !important;
  min-width: 0 !important;
}

.cornerstone-dropdown-table {
  .table {
    border-collapse: collapse;
    max-width: 90vw;
    overflow-x: scroll;
    display: block;
    margin-bottom: 0;

    .thead,
    .tbody,
    ul,
    li {
      display: block;
    }
    .tbody,
    ul {
      overflow-y: scroll;
      overflow-x: hidden;
    }

    // .thead > .tr {
    //   overflow: hidden;
    //   overflow-y: scroll;
    // }
    // .tr,
    // li {
    //   display: -webkit-box;
    //   display: -webkit-flex;
    //   display: -ms-flexbox;
    //   display: flex;
    // }

    ul {
      padding: 0;
    }
    .tr,
    li {
      white-space: nowrap;
      overflow: unset;
      line-height: 11px !important;
    }
    .th,
    .td {
      display: inline-block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      height: 32px !important;
    }
  }
}
