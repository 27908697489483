// ul.ant-menu.ant-menu-sub.ant-menu-inline {
//   padding-left: 48px;
// }
.side-menu {
  ul li,
  ol li {
    color: inherit !important;
  }
}

.side-menu.ant-menu-inline,
.side-menu.ant-menu-vertical,
.side-menu.ant-menu-vertical-left {
  border-right: none;
  background: transparent;
}

.icon-title {
  display: inline-block;
  max-width: 0;
  opacity: 0;
}

.ant-menu-inline-collapsed {
  .menu-item-wrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    height: 100%;

    .anticon + .menu-item-title {
      position: absolute;
      right: 0;
    }

    .icon-title {
      margin-top: 2px;
      align-self: center;
      max-width: unset;
      font-size: 10px;
      opacity: 1;
    }

    .anticon,
    .icon-title {
      line-height: 100% !important;
    }
  }
}

.ant-tooltip-inner .icon-title {
  line-height: 0 !important;
}

li.ant-menu-item.ant-menu-item-selected {
  background: #061777;

  a {
    color: #fff;
  }
}

.ant-menu-sub.ant-menu-inline {
  color: #fff;
  background: transparent;
}

// .ant-layout-sider-children {
//   background-color: #00305e;
// }

ul.ant-menu.ant-menu-sub.ant-menu-inline {
  .menu-item-wrapper > i:not(:has(svg)) {
    margin-left: -13px !important;
  }
}
