@import "../../assets/scss/theme";

.ant-btn-primary:active,
.ant-btn-primary.active {
  color: $btn-primary-color;
  background-color: $btn-primary-bg;
  border-color: $btn-default-border;
  opacity: 0.5;
}
.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: $btn-primary-color;
  background-color: $btn-primary-bg;
  border-color: $btn-default-border;
  opacity: 0.7;
}
