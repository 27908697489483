/** Applying common client side field error message styles*/
@mixin error-message-styles {
  position: absolute;
  font-size: 11px;
  right: 0;
}

/**Center an element */
@mixin push--auto {
  margin: {
    left: auto;
    right: auto;
  }
}
@mixin button-styles($width: 101px) {
  min-width: $width;
}

/**Apply font styles**/
@mixin font-source-ui(
  $size: false,
  $colour: false,
  $weight: false,
  $lh: false,
  $family: false
) {
  @if $family {
    font-family: $family;
  }
  @if $size {
    @include font-size($size);
  }
  @if $colour {
    color: $colour;
  }
  @if $weight {
    font-weight: $weight;
  }
  @if $lh {
    line-height: $lh;
  }
}
/* *Apply font size based on rem* */
@mixin font-size($size, $base: 16) {
  @if $size == "inherit" {
    font-size: "inherit";
  } @else {
    font-size: $size; // fallback for old browsers
    font-size: ($size / $base) * 1rem;
  }
}
/* *Text overflow truncate * */
@mixin truncate($truncation-boundary) {
  max-width: $truncation-boundary;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
/* *Make unselectable* */
@mixin unselectable {
  -webkit-touch-callout: none;
  user-select: none;
}
/* *Media query mixins * */
@mixin breakpoint($point) {
  @if $point == large {
    @media only screen and (min-width: 1366px) {
      @content;
    }
  } @else if $point == desktop {
    @media only screen and (min-width: 1024px) {
      @content;
    }
  } @else if $point == tablet {
    @media only screen and (min-width: 600px) {
      @content;
    }
  }
}
/* *Gradient mixins* */
@mixin gradient($start-color, $end-color, $orientation) {
  background: $start-color;
  @if $orientation == "vertical" {
    background: -webkit-linear-gradient(top, $start-color, $end-color);
    background: linear-gradient(to bottom, $start-color, $end-color);
  } @else if $orientation == "horizontal" {
    background: -webkit-linear-gradient(left, $start-color, $end-color);
    background: linear-gradient(to right, $start-color, $end-color);
  } @else {
    background: -webkit-radial-gradient(
      center,
      ellipse cover,
      $start-color,
      $end-color
    );
    background: radial-gradient(ellipse at center, $start-color, $end-color);
  }
}
@mixin font-styles {
  font-family: $estee-noto-font-family;
  font-size: inherit;
}
@mixin remove-left-border {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
@mixin remove-right-border {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
@mixin ripple-element($ripple-color, $btn-type) {
  @if ($btn-type == "primary") {
    background: $ripple-color
      radial-gradient(circle, transparent 1%, lighten($ripple-color, 20%) 1%)
      center/15000%;
  } @else {
    background: $ripple-color
      radial-gradient(circle, transparent 1%, darken($ripple-color, 15%) 1%)
      center/15000%;
  }
}
@mixin ripple-element-active($ripple-color) {
  background-color: $ripple-color;
  background-size: 100%;
  transition: background 0s;
}
/* ------Adding Media Query Mixin ----------

Usage
------
.site-header {
    padding: 2rem;
    font-size: 1.8rem;
    @include mq('tablet-wide') {
        padding-top: 4rem;
        font-size: 2.4rem;
    }
} */
$breakpoints: (
  "phone": 400px,
  "phone-wide": 480px,
  "phablet": 560px,
  "tablet-small": 640px,
  "tablet": 768px,
  "tablet-wide": 1024px,
  "desktop": 1248px,
  "desktop-wide": 1440px
);
@mixin mq($width, $type: min) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    @if $type == max {
      $width: $width - 1px;
    }
    @media only screen and (#{$type}-width: $width) {
      @content;
    }
  }
}
