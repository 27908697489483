.app-base-header {
  height: 54px;
  background-color: #dedede;
  position: fixed !important;
  left: 0;
  right: 0;
  z-index: 2;
  margin-left: -8px !important;
}

.top-head-avatar {
  background-color: #dedede;
  color: #333;
}

.job-alert-nav-item {
  position: relative;
  &.job-nav-item-selected {
    background-color: #fff;
    .top-head-avatar {
      background-color: #fff;
    }
  }
}

.job-alerts,
.widget-setting1 {
  position: absolute;
  top: 54px;
  right: 0;
  width: calc(99vw - 200px); /*width 100vw minus sider width 200px*/
  height: 90vh;
  background: #fff;
}

.hide {
  display: none;
}
#app-base-layout .ant-avatar.ant-avatar-icon {
  line-height: 35px !important;
}
.dropdown {
  .dropdown-menu {
    font-size: 13px !important;
  }
}
.rds-location {
  color: #007bff;
  margin-top: 0.7em;
  font-size: 12px !important;
  padding-right: 0em;
  .nav-link {
    padding-right: 0em !important;
  }
}
#app-base-layout .user-name-loc {
  margin-top: 0.5rem;
  line-height: 0.25rem;
}
