@import "../../assets/scss/theme";
#overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $card-head-background;
  z-index: 99999;
  cursor: pointer;
  text-align: center;
}
#overlay .overlay-box {
  top: 50%;
  position: relative;
  margin: auto;
}

.spinner-container {
  text-align: center;
  border-radius: 4px;
  padding: 30px 50px;
  margin: 20px 0;
}

.spinner-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  .spinner-overlay-inner {
    margin: auto;
    top: 50%;
    position: relative;
  }
}
