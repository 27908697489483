.tech_type_modal {
  height: 130px;
}
.input-box {
  width: 100%;
  height: 108px;
  padding-right: 10px !important;
}
.button-margin-tdselect {
  margin-left: 12px;
}
.td-select-type-loc {
  .promise-modal .fixedFooter {
    position: relative;
  }
}
